<template>
  <div class="page-container">
    <h2 class="w-100 text-center graph-title">Flüssigkeiten erkennen</h2>
    <div class="chart-container">
      <DataChart :chart-data="impChartData" :options="impChartOptions" :styles="chartStyle"/>
    </div>
    <div class="form-container">
      <b-container>
        <b-row>
          <b-col>
            <b>Impedanz Mittelwert:</b>
            <p>{{ curImpAvg.toFixed() }}</p>
          </b-col>
          <!--
          <b-col>
            <b>Gain factor</b>
              <p>{{gainFactor}}</p>
          </b-col>
        -->
        </b-row>
      </b-container>
      <b-form class="form-content" @submit="onSubmit">
        <b-container>
          <b-form-row>
            <!--
            <b-col class="col-style">
              <b-button class="submit-button" size="sm" variant="danger" @click="startCalibration()">Kallibirieren</b-button>
            </b-col>
          -->
            <b-col>
              <b-button class="submit-button" type="submit" size="sm" variant="primary">Messung starten</b-button>
            </b-col>
          </b-form-row>
          <!--
          <b-form-row>
            <b-col>
              <b-button class="submit-button" size="sm" variant="info" @click="startLiquidCalibration(1)">W</b-button>
            </b-col>
            <b-col>
              <b-button class="submit-button" size="sm" variant="danger" @click="startLiquidCalibration(2)">O</b-button>
            </b-col>
            <b-col>
              <b-button class="submit-button" size="sm" variant="warning" @click="startLiquidCalibration(3)">B</b-button>
            </b-col>
            <b-col>
              <b-button class="submit-button" size="sm" variant="dark" @click="startLiquidCalibration(4)">R</b-button>
            </b-col>
          </b-form-row>
          -->
        </b-container>
      </b-form>
    </div>
    <b-container class="results-div border-top border-dark">
      <b-row class="vh-100">
        <b-col class="results-col border-right border-dark">
          <p>
            <b>Bekannte Flüssigkeiten:</b><br>
            <!--<cup-water-icon class="water-icon"/> <br>-->
            <b-button class="calib-button" size="m" variant="info" @click="startLiquidCalibration(1)"> </b-button> <br />
            Flüssigkeit 1
            <br>
            <!--<img src="../static/orange-icon.png" width="20" height="20"><br>-->
            <b-button class="calib-button" size="m" variant="danger" @click="startLiquidCalibration(2)"> </b-button> <br />
            Flüssigkeit 2
            <br>
            <!--<img src="../static/banana-icon.png" width="20" height="20"><br>-->
            <b-button class="calib-button" size="m" variant="warning" @click="startLiquidCalibration(3)"> </b-button> <br />
            Flüssigkeit 3
            <br>
            <!--<img src="../static/wine-beer-icon.png" width="10" height="32"><br>-->
            <b-button class="calib-button" size="m" variant="dark" @click="startLiquidCalibration(4)"> </b-button> <br />
            Flüssigkeit 4
            <br>
          </p>
        </b-col>
        <b-col class="results-col">
          <b>Ergebnis:</b><br>
          <br>
          <br>
          <beaker-question-icon class="unknown-icon-res" :size="80" v-if="liquidClass == 0" />
          <div class="liquid_class1" v-if="liquidClass == 1">Flüssigkeit 1</div>
          <div class="liquid_class2" v-if="liquidClass == 2">Flüssigkeit 2</div>
          <div class="liquid_class3" v-if="liquidClass == 3">Flüssigkeit 3</div>
          <div class="liquid_class4" v-if="liquidClass == 4">Flüssigkeit 4</div>

          <!--<cup-water-icon class="water-icon" :size="80" v-if="liquidClass == 1" />
          <img src="../static/orange-icon.png" width="80" height="80" v-if="liquidClass == 2">
          <img src="../static/banana-icon.png" width="80" height="80" v-if="liquidClass == 3">
          <img src="../static/wine-beer-icon.png" width="30" height="96" v-if="liquidClass == 4">-->
          <!--<img src="../static/peach-fruit-icon.png" width="80" height="80" v-if="liquidClass == 4">-->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DataChart from '../components/DataChart.js'

import BeakerQuestionIcon from 'vue-material-design-icons/BeakerQuestion.vue'
//import CupWaterIcon from 'vue-material-design-icons/CupWater.vue'

export default {
  name: "DataGraphBioz",
  components: {
    DataChart,
    BeakerQuestionIcon,
    //CupWaterIcon,
  },

  data () {
    return {     
      sImpedance: {
        cComplexUuid: '00002221-702b-69b5-b243-d6094a2b0e24',
        cComplexConfUuid: '00002222-702b-69b5-b243-d6094a2b0e24',
        cCalibUuid: '00002223-702b-69b5-b243-d6094a2b0e24'
      },

      chartStyle: {
        height: '100%',
      },

      impChartOptions: {
        animation: {
          duration: 0
        },
        title: {
          display: true,
          text: "Impedanz",
          fontColor: '#2c3e50',
          fontSize: "14"
        },        
        legend: {
          display: false,
          labels: {
            boxWidth: 0,
            boxHeight: 0,
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Frequenz in Hz',
              fontColor: '#2c3e50',
              fontSize: "14"
            },
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }],
          yAxes: [{
            ticks: {
              userCallback(value) {
                if(value >= 1000) {
                  value /= 1000
                  return value.toLocaleString() + 'k'
                }
                return value
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },      

      gainFactor: 1.0279432416382406e-8, //4.987009916135321e-9, altes eval board
      gainRefResisor: 20000,

      impChartData: null,

      scanStarted: false,
      lastFrequency: 0,

      fStart: 1600,
      fStep: 5,
      nInc: 25,
      scanConf: [
        0b00000001, // Enable sensor, default period
        this.nInc & 0xFF, (this.nInc >> 8) & 0xFF,
        this.fSep & 0xFF, (this.fStep >> 8) & 0xFF,
        this.fStart & 0xFF, (this.fStart >> 8) & 0xFF, (this.fStart >> 16) & 0xFF, (this.fStart >> 24) & 0xFF
      ],

      curImpAvg: 0,
      curImpMax: 0,

      liquidClass: 0,

      impMaxFactor: 1.05,
      impMinFactor: 0.95,

      calibWater: false,
      waterImpTop: 45000,
      waterImpBot: 30100,
      calibBanana: false,
      bananaImpTop: 24000,
      bananaImpBot: 22000,
      calibOrange: false,
      orangeImpTop: 30000,
      orangeImpBot: 24100,
      calibRum: false,
      rumImpTop: 200000,
      rumImpBot: 290000,
    }
  },

  mounted() {
    let bufWater = this.$store.state.liquidClassValues.impMaxWater
    let bufOrange = this.$store.state.liquidClassValues.impMaxOrange
    let bufBanana = this.$store.state.liquidClassValues.impMaxBanana
    let bufRum = this.$store.state.liquidClassValues.impMaxRum

    if(bufWater != null) {
      this.waterImpTop = bufWater * this.impMaxFactor
      this.waterImpBot = bufWater * this.impMinFactor
      console.log("Setting waterImpTop: " + this.waterImpTop + " waterImpBot: " + this.waterImpBot)
    }

    if(bufOrange != null) {
      this.orangeImpTop = bufOrange * this.impMaxFactor
      this.orangeImpBot = bufOrange * this.impMinFactor
      console.log("Setting orangeImpTop: " + this.orangeImpTop + " orangeImpBot: " + this.orangeImpBot)
    }

    if(bufBanana != null) {
      this.bananaImpTop = bufBanana * this.impMaxFactor
      this.bananaImpBot = bufBanana * this.impMinFactor
      console.log("Setting bananaImpTop: " + this.bananaImpTop + " bananaImpBot: " + this.bananaImpBot)
    }

    if(bufRum != null) {
      this.rumImpTop = bufRum * this.impMaxFactor
      this.rumImpBot = bufRum * this.impMinFactor
      console.log("Setting rumImpTop: " + this.rumImpTop + " rumImpBot: " + this.rumImpBot)
    }
  },

  methods: {
    async onSubmit(event) {
      event.preventDefault()

      if(!this.$store.state.sImpedanceAvailable) return

      this.$store.commit('clearImpData')

      // this.fStart = 1400
      // this.nInc = 10
      // this.fStep = 50
      this.lastFrequency = parseInt(this.fStart) + (parseInt(this.nInc) * parseInt(this.fStep))

      await this.startScan()
    },

    async startScan() {
      let scanConf = this.parseScanConf()

      if(this.scanStarted) {
        await this.stopScan()
      }

      await this.$store.state.impHandles.complexConf.writeValue(scanConf)

      this.$store.state.impHandles.complexData.addEventListener(
                                            'characteristicvaluechanged', 
                                            this.liquidEventHandler)
      await this.$store.state.impHandles.complexData.startNotifications()

      this.curImpAvg = 0
      this.scanStarted = true
    },

    async stopScan() {
      const buf = new ArrayBuffer(9)
      let scanStopConf = new Uint8Array(buf)
      for(let i = 0; i < 9; i++) {
        scanStopConf[i] = 0
      }

      await this.$store.state.impHandles.complexConf.writeValue(scanStopConf)

      this.$store.state.impHandles.complexData.removeEventListener('characteristicvaluechanged', 
                                                                        this.liquidEventHandler)
      this.scanStarted = false   
    },

    async onScanFinished() {
      await this.stopScan()

      if(this.calibWater) {
        this.waterImpTop = this.curImpMax * this.impMaxFactor
        this.waterImpBot = this.curImpMax * this.impMinFactor
        this.calibWater = false
        console.log("curImpMax: " + this.curImpMax)
        console.log("waterImpBot: " + this.waterImpBot)
        console.log("waterImpTop: " + this.waterImpTop)

        this.$store.commit('updateImpMaxWater', this.curImpMax)

      }else if(this.calibOrange) {
        this.orangeImpTop = this.curImpMax * this.impMaxFactor
        this.orangeImpBot = this.curImpMax * this.impMinFactor
        this.calibOrange = false
        console.log("curImpMax: " + this.curImpMax)
        console.log("orangeImpTop: " + this.orangeImpTop)
        console.log("orangeImpBot: " + this.orangeImpBot)

        this.$store.commit('updateImpMaxOrange', this.curImpMax)

      }else if(this.calibBanana) {
        this.bananaImpTop = this.curImpMax * this.impMaxFactor
        this.bananaImpBot = this.curImpMax * this.impMinFactor
        this.calibBanana = false
        console.log("curImpMax: " + this.curImpMax)
        console.log("bananaImpTop: " + this.bananaImpTop)
        console.log("bananaImpBot: " + this.bananaImpBot)

        this.$store.commit('updateImpMaxBanana', this.curImpMax)

      }else if(this.calibRum) {
        this.rumImpTop = this.curImpMax * this.impMaxFactor
        this.rumImpBot = this.curImpMax * this.impMinFactor
        this.calibRum = false
        console.log("curImpMax: " + this.curImpMax)
        console.log("rumImpTop: " + this.rumImpTop)
        console.log("rumImpBot: " + this.rumImpBot)

        this.$store.commit('updateImpMaxRum', this.curImpMax)

      }

      this.setLiquidClass()
    },

    async handleImpedanceData(data) {
      let finished = data.fHz == this.lastFrequency

      if(this.curImpAvg == 0) {
        this.curImpAvg = data.imp 
      } else {
        this.curImpAvg = (this.curImpAvg + data.imp) / 2
      }

      this.curImpMax = this.$store.getters.getImpMaxData

      console.log(data.real + ";" + data.imag + ";" + data.fHz + ";" + data.imp + ";" + data.mag + ";" + this.curImpAvg)
      this.$store.commit('updateImpData', data)
      this.updateChart()

      if(finished) {
        this.onScanFinished()
      }
    },

    parseScanConf() {
      const buf = new ArrayBuffer(9)
      let scanConf = new Uint8Array(buf)
      scanConf[0] = 0b00000001 // Enable - Default period
      scanConf[1] = this.nInc & 0xFF
      scanConf[2] = (this.nInc >> 8) & 0xFF
      scanConf[3] = this.fStep & 0xFF
      scanConf[4] = (this.fStep >> 8) & 0xFF
      scanConf[5] = this.fStart & 0xFF
      scanConf[6] = (this.fStart >> 8) & 0xFF
      scanConf[7] = (this.fStart >> 16) & 0xFF
      scanConf[8] = (this.fStart >> 24) & 0xFF

      return scanConf
    },

    liquidEventHandler(event) {
      const targetUuid = event.target.uuid
      const rawData = event.target.value

      if(targetUuid === this.sImpedance.cComplexUuid) {
        let data = this.parseImpedanceData(rawData)
        this.handleImpedanceData(data)

      } else if(targetUuid === this.sImpedance.cCalibUuid) {
        let calibData = this.parseCalibData(rawData)
        this.gainFactor = calibData.gain
        console.log("Calibrated for midpoint freq " + calibData.fHz)
        console.log("Gain factor " + calibData.gain)

      } else {
        console.log('liquidEventHandler: Unknown target uuid ' + targetUuid)
        console.log(rawData)
      }
    },

    parseImpedanceData(rawData) {
      let timestamp = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))

      let fHz = (rawData.getUint8(4) | (rawData.getUint8(5) << 8) 
        | (rawData.getUint8(6) << 16) | (rawData.getUint8(7) << 24))

      const int16View = new Int16Array(rawData.buffer)
      let real = int16View[4]
      let imag = int16View[5]

      //console.log("f: " + fHz + " real: " + real + " imag: " + imag)
      let {imp, mag} = this.calcImpedanceMagnitude(fHz, real, imag)
      //console.log(imp + " " + mag)

      return {ts: timestamp, fHz: fHz, real: real, imag: imag, imp: imp, mag: mag}
    },

    parseCalibData(rawData) {
        let fHz = (rawData.getUint8(0) | (rawData.getUint8(1) << 8) 
        | (rawData.getUint8(2) << 16) | (rawData.getUint8(3) << 24))
        //console.log(fHz)

        const f32View = new Float32Array(rawData.buffer.slice(4))
        //console.log(f32View)

        return {fHz: fHz, gain: f32View[0]}
    },

    calcImpedanceMagnitude(frequency, real, imag) {
      let magnitude = Math.sqrt( (Math.pow(real, 2) + Math.pow(imag, 2)) )
      let impedance = 1 / (this.gainFactor * magnitude)

      //console.log(frequency + " Hz " + impedance + " Ohms")

      return {imp: impedance, mag: magnitude}
    },

    calcCurrentGain(real, imag) {
      let magnitude = Math.sqrt( (Math.pow(real, 2) + Math.pow(imag, 2)) )
      let currentGain = (1/this.gainRefResisor) / magnitude

      return {mag: magnitude, gain: currentGain}
    },

    async startLiquidCalibration(liquid) {
      if(!this.$store.state.sImpedanceAvailable) return

      if(liquid === 1) {
        console.log("calibWater")
        this.calibWater = true
      }else if(liquid === 2) {
        console.log("calibOrange")
        this.calibOrange = true
      }else if(liquid === 3) {
        console.log("calibBanana")
        this.calibBanana = true
      }else if(liquid === 4) {
        console.log("calibRum")
        this.calibRum = true
      }

      this.lastFrequency = parseInt(this.fStart) + (parseInt(this.nInc) * parseInt(this.fStep))
      this.$store.commit('clearImpData')
      await this.startScan()
    },

    async startCalibration() {
      console.log("startCalibration")
      if(this.submitDisable) return
      if(!this.$store.state.sImpedanceAvailable) return

      this.$store.state.impHandles.calib.addEventListener(
                                            'characteristicvaluechanged', 
                                            this.liquidEventHandler)

      await this.$store.state.impHandles.calib.startNotifications()

      let scanConf = this.parseScanConf()
      await this.$store.state.impHandles.calib.writeValue(scanConf)
    },

    setLiquidClass() {
      let maxImp = this.$store.getters.getImpMaxData
      console.log("setLiquidClass maxImp: " + maxImp)

      if(maxImp >= this.bananaImpBot && maxImp <= this.bananaImpTop) {
        this.liquidClass = 3    // banana
      }else if(maxImp >= this.orangeImpBot && maxImp <= this.orangeImpTop) {
        this.liquidClass = 2    // orange
      }else if(maxImp >= this.waterImpBot && maxImp <= this.waterImpTop) {
        this.liquidClass = 1    // water
      }else if(maxImp >= this.rumImpBot && maxImp <= this.rumImpTop) {
        this.liquidClass = 4    // rum
      }else {
        this.liquidClass = 0
      }
    },

    async updateChart() {
      let labels = this.$store.state.impData.fHz
      let realData = this.$store.state.impData.real
      let imagData = this.$store.state.impData.imag
      let impData = this.$store.state.impData.imp
      let magData = this.$store.state.impData.mag

      this.realChartData = {
        labels: labels,
        datasets: [{
          label: 'Real',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: realData
        }]
      }

      this.imagChartData = {
        labels: labels,
        datasets: [{
          label: 'Imaginär',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: imagData
        }]
      }

      this.impChartData = {
        labels: labels,
        datasets: [{
          label: 'Impedanz',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: impData
        }]
      }

      this.magChartData = {
        labels: labels,
        datasets: [{
          label: 'Magnitude',
          lineTension: 0,
          borderWidth: 2,
          borderColor: '#2c3e50',
          pointBackgroundColor: '#2c3e50',
          backgroundColor: 'transparent',
          data: magData
        }]
      }
    },
  }, // methods

  async beforeDestroy() {
    if(this.$store.state.bleConnected) {
      await this.$store.state.impHandles.complexData.stopNotifications()
      await this.stopScan()
    }
  }
}
</script>

<style scoped>
.page-container {
  width: 100%;
  height: 100%;
  font-size: 14px;
}
.charts {
  display: inline-block;
  height: 66%;
  width: 95%;
  /*padding-bottom: 10px;*/
}
.chart-container {
  display: inline-block;
  height: 33%;
  width: 95%;
  padding-bottom: 10px;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 28%;
  font-size: 14px;
}
.col-style {
  display: flex;
  justify-content: center;
  align-items: center;  
}
.input-style {
  width: 50%;
}
.submit-button {
  margin-top: 13px;
  margin-right: 10px;
}
.calib-button {
  height: 25px;
}

.graph-title {
  font-size: 18px;
}
.results-div {
  height: 32%;
  font-size: 14px;
}
.water-icon {
  color: lightblue;
}
.liquor-icon {
  color: brown;
}
.beer-icon {
  color: yellow;
}
.wine-icon {
  color: darkred;
}
.coffee-icon {
  color: brown;
}
.unknown-icon-res {
  font-size: 50px;
}

.liquid_class1 {
  display:flex  ;
  background-color: #17a2b8;
  height: 80px;
  width:  80px;
  margin: auto;
  align-items : center;
  justify-content: center;
}

.liquid_class2 {
  display:flex  ;
  background-color: #dc3545;
  height: 80px;
  width:  80px;
  margin: auto;
  align-items : center;
  justify-content: center;
}

.liquid_class3 {
  display:flex  ;
  background-color: #ffc107;
  height: 80px;
  width:  80px;
  margin: auto;
  align-items : center;
  justify-content: center;
}

.liquid_class4 {
  display:flex  ;
  background-color: #343a40;
  color:  white;
  height: 80px;
  width:  80px;
  margin: auto;
  align-items : center;
  justify-content: center;
}
/*.results-col {
  padding-top: 5%;
}*/

/*.liquid-icon-res {
  width: 50px;
  height: 50px;
  font-size: 50px;
}*/
</style>